<template>
	<div id="couponList">
		<page-coupon-list :is-shop="1"></page-coupon-list>
	</div>
</template>

<script>
	import PageCouponList from '@/components/layout/coupon/page-coupon-list.vue'
	export default {
		components:{
			PageCouponList
		}
	}
</script>

<style>
</style>
